/* @font-face {
  font-family: 'Bring Font';
  src: url('./_assets/font/YOURNOWSans-Regular.woff2');
  src: local('Bring Font'), local('Bring Font'), url('./_assets/font/YOURNOWSans-Regular.woff2') format('opentype');
}

@font-face {
  font-family: 'Bring Font';
  src: url('./_assets/font/YOURNOWSans-Medium.woff2');
  src: local('Bring Font'), local('Bring Font'), url('./_assets/font/YOURNOWSans-Medium.woff2') format('opentype');
  font-weight: medium;
}

@font-face {
  font-family: 'Bring Font';
  src: url('./_assets/font/YOURNOWSans-Bold.woff2');
  src: local('Bring Font'), local('Bring Font'), url('./_assets/font/YOURNOWSans-Bold.woff2') format('opentype');
  font-weight: bold;
} */

@font-face {
  font-family: 'Bring Font';
  src: url('./_assets/font/ProximaNova-Regular.woff2');
  src: local('Bring Font'), local('Bring Font'), url('./_assets/font/ProximaNova-Regular.woff2') format('opentype');
}

@font-face {
  font-family: 'Bring Font';
  src: url('./_assets/font/ProximaNova-Medium.woff2');
  src: local('Bring Font'), local('Bring Font'), url('./_assets/font/ProximaNova-Medium.woff2') format('opentype');
  font-weight: medium;
}

@font-face {
  font-family: 'Bring Font';
  src: url('./_assets/font/ProximaNova-Bold.woff2');
  src: local('Bring Font'), local('Bring Font'), url('./_assets/font/ProximaNova-Bold.woff2') format('opentype');
  font-weight: bold;
}

/*********************** Common Styling ***********************/

body {
  color: #222222;
  /* scroll-behavior: smooth; */
  font-family: 'Bring Font', sans-serif;
}

p {
  font-size: 16px;
  color: #222222;
  line-height: 1.75rem;
  font-family: 'Bring Font', sans-serif;
}

.custom-link {
  color: #a633db;
  text-decoration: none;
  transition: all .3s ease;
}

.custom-link:hover {
  color: #f16b2f;
}

img {
  max-width: 100%;
}

/*********************** Main Heading Style ***********************/

.common-heading-main {
  text-align: center;
  position: relative;
  min-height: 130px;
}

.common-heading {
  color: #222222;
  font-weight: 700;
  position: relative;
  font-size: 40px;
  line-height: 46px;
  margin: 0;
  margin-bottom: 30px;
}

.common-heading-slogan {
  margin: 0 0 10px;
  display: inline-block;
  color: #a633db;
  line-height: 28px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.common-heading-line {
  position: relative;
}

.common-heading-line::before {
  content: "";
  width: 12px;
  height: 4px;
  background-color: #f16b2f;
  position: absolute;
  bottom: -4px;
  left: 50%;
  margin-left: -35px;
  transform: translateX(-50%);
  border-radius: 2px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  z-index: 1;
}

.common-heading-line::after {
  content: "";
  width: 65px;
  height: 4px;
  background-color: #a633db;
  position: absolute;
  bottom: -4px;
  right: 0;
  left: 50%;
  margin-left: -20px;
  border-radius: 2px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  z-index: 0;
}

.common-heading-main:hover .common-heading-line::before {
  width: 65px;
  margin-left: -5px;
  background-color: #f16b2f;
}

.common-heading-main:hover .common-heading-line::after {
  width: 12px;
  margin-left: 35px;
  background-color: #a633db;
}

/*********************** Section Heading Style ***********************/

.section-heading {
  font-size: 36px;
  font-weight: bold;
  line-height: 1.1em;
}

.section-heading-text {
  color: #222222;
}

/*********************** Pages Heading Styling ***********************/

.page-heading {
  padding: 30px 0;
}

.page-heading h1 {
  font-size: 50px;
}

.breadcrumbs {
  margin: 0;
  padding: 0;
}

.breadcrumbs li {
  list-style-type: none;
  display: inline-block;
  padding-right: 10px;
}

.breadcrumbs li a {
  color: #222222;
  text-decoration: none;
  transition: all .4s ease;
}

.breadcrumbs li a:hover {
  color: #f16b2f;
}

.breadcrumbs li.active {
  color: #a633db;
}

.page-heading p {
  color: #222222;
}

/*********************** Banner Styling ***********************/

.banner {
  position: relative;
  background-position: center;
  background-size: cover;
  min-height: calc(100vh - 98px);
}

.banner::before {
  content: '';
  position: absolute;
  top: 20%;
  left: -100px;
  width: 200px;
  height: 200px;
  background-image: url("./_assets/images/circle-dark1.png");
  background-size: 100%;
}

.banner-inner {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.banner-heading {
  margin-top: 0;
  font-weight: 900;
  font-size: 3rem;
  color: #a633db;
  margin-bottom: 2.75rem;
}

.banner-heading-slogan {
  font-size: 2rem;
  color: #f16b2f;
  font-weight: 600;
  margin-left: 2px;
  margin-bottom: 15px;
}

.banner-text {
  margin-left: 2px;
  color: #222222;
  font-size: 18px;
  line-height: 29px;
  text-align: justify
}

.banner-img {
  margin-bottom: 100px;
  position: relative;
}

.banner-img img {
  width: 100%;
}

/* .banner-img::before {
  content: '';
  position: absolute;
  top: 0;
  width: 250px;
  height: 245px;
  -webkit-animation: spin 60s linear infinite;
  -moz-animation: spin 60s linear infinite;
  animation: spin 60s linear infinite;
  background-image: url("./_assets/images/circle-dots.png");
} */

.banner-link a {
  display: inline-block;
  text-decoration: none;
  padding: 10px 25px;
  color: #ff6c2f;
  background-color: #fbd6c4;
  font-size: 18px;
  border-radius: 25px;
  margin-top: 2rem;
  transition: all .3s ease;
}

.banner-link a:hover {
  color: #ffffff;
  background-color: #ff6c2f;
}

/*********************** Social icons styling ***********************/

.social-icons-main {
  position: absolute;
  bottom: 5%;
  left: 2%;
  z-index: 2;
}

.social-icons {
  padding: 0;
  margin: 0;
}

.social-icons li {
  cursor: pointer;
  width: 35px;
  height: 35px;
  line-height: 42px;
  border-radius: 50%;
  margin-bottom: 10px;
  text-align: center;
  list-style-type: none;
  transition: all .4s ease;
  border: 1px solid #cdcdcd;
}

.social-icons li a {
  text-decoration: none;
  font-size: 18px;
  color: #222222;
  transition: all .4s ease;
}

.social-icons li:hover, .social-icons li:hover a {
  color: #f16b2f;
  border-color: #f16b2f;
}

/*********************** Partner Styling ***********************/

.partner-section {
  position: relative;
  padding-top: 100px;
  padding-bottom: 150px;
  background: #fafafa;
}

/* .partner-section::before {
  content: '';
  position: absolute;
  top: 5%;
  right: -100px;
  width: 200px;
  height: 200px;
  background-image: url("./_assets/images/circle-dark.jpg");
  background-size: 100%;
} */

.partner-logo {
  padding-top: 70px;
  width: 130px;
  max-width: 100%;
  height: auto;
  margin: auto;
  transition: all .4s ease;
}

/* .partner-logo:hover img {
  cursor: pointer;
  filter: grayscale(0);
} */

.partner-logo img {
  width: 100%;
  max-height: 60px;
  filter: grayscale(1);
  transition: all .4s ease;
}

.pb-350 {
  padding-bottom: 350px;
}

/*********************** Trust box Styling ***********************/

.trust-box {
  position: relative;
  margin-top: -225px;
  padding: 96px 0px 96px 0px;
}

/* .trust-box::before {
  content: '';
  position: absolute;
  bottom: 5%;
  left: 5%;
  width: 250px;
  height: 245px;
  -webkit-animation: spin 60s linear infinite;
  -moz-animation: spin 60s linear infinite;
  animation: spin 60s linear infinite;
  background-image: url("./_assets/images/circle-dots.png");
} */

.trust-box-inner {
  padding: 30px 0;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 0px 60px -12px rgb(0 0 0 / 12%);
}

.trust-img {
  border-radius: 16px;
}

.trust-img img {
  width: 100%;
  border-radius: 16px;
}

.partner-list ul {
  padding: 0;
  margin: 0;
}

.partner-list ul li {
  list-style-type: none;
  color: #222222;
  margin-bottom: 10px;
  padding-left: 25px;
  position: relative;
  font-size: 17px;
}

.trust-li-icon {
  color: #f16b2f;
  padding-right: 15px;
  position: absolute;
  left: 0;
}

/*********************** Services styling ***********************/

.services-section {
  position: relative;
  padding: 100px 0;
  background-color: #fafafa;
  background-image: url("./_assets/images/banner-services.png");
  background-size: cover;
}

/* .services-section::before {
  content: '';
  position: absolute;
  bottom: 5%;
  right: 5%;
  width: 250px;
  height: 245px;
  -webkit-animation: spin 60s linear infinite;
  -moz-animation: spin 60s linear infinite;
  animation: spin 60s linear infinite;
  background-image: url("./_assets/images/circle-dots.png");
} */

.services-main {
  padding: 100px 0;
}

.services-box {
  padding: 15px 0;
}

.service-box-inner {
  position: relative;
  background-color: #FFFFFF;
  padding: 50px 40px 60px 40px;
  border-top: 2px solid transparent;
  transition: all .4s ease;
}

.service-box-inner:hover {
  border-top: 2px solid #f16b2f;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.service-icon {
  margin-bottom: 15px;
  margin-top: 11px;
  display: inline-block;
  padding: 5px;
  font-size: 50px;
  line-height: 50px;
  color: #a633db;
}

.service-heading {
  padding: 10px 0;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  margin: 0 0 12px;
}

.service-box-inner p {
  color: #222222;
}

.service-number {
  position: absolute;
  right: 2%;
  top: 3%;
  color: rgb(0 0 0 / 5%);
  font-size: 60px;
  font-weight: 700;
}

/*********************** Why Choose Us Styling ***********************/

.why-choose-us {
  position: relative;
  padding: 100px 0;
  background-color: #fafafa;
}

/* .why-choose-us::before {
  content: '';
  position: absolute;
  left: 42.25%;
  top: 45%;
  width: 250px;
  height: 245px;
  -webkit-animation: spin 60s linear infinite;
  -moz-animation: spin 60s linear infinite;
  animation: spin 60s linear infinite;
  background-image: url("./_assets/images/circle-dots.png");
} */

.why-choose-us-inner {
  padding: 70px 0;
}

.why-choose-us-img img {
  max-width: 100%;
  margin: auto;
}

/*********************** Testimonial Styling ***********************/

.testimonial-section {
  padding: 100px 0;
  position: relative;
  background-color: #fafafa;
  background-image: url("./_assets/images/banner-services.png");
  background-size: cover;
}

/* .testimonial-section::before {
  content: '';
  position: absolute;
  left: 2%;
  top: 25%;
  width: 250px;
  height: 245px;
  -webkit-animation: spin 60s linear infinite;
  -moz-animation: spin 60s linear infinite;
  animation: spin 60s linear infinite;
  background-image: url("./_assets/images/circle-dots.png");
} */

.testimonial-section-inner {
  padding-top: 70px;
}

.testimonial-card {
  padding: 25px;
  margin: 10px 0;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  transition: all .4s ease;
}

.testimonial-card:hover {
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.testimonial-card .card-header {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.testimonial-card .card-header img {
  width: 50px;
  margin-right: 10px;
  border-radius: 50%;
}

.testimonial-card .card-header .header-details h4 {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
}

.testimonial-card .card-header .header-details p {
  margin: 0;
  color: #979797;
}

.testimonial-card-icon {
  position: absolute;
  top: 5px;
  right: 0;
  height: 35px;
  width: 35px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.testimonial-card-icon {
  color: #f16b2f;
}

.testimonial-card .card-body p {
  color: #222222;
}

.filters {
  margin-bottom: 30px;
}

/*********************** Footer Styling ***********************/

.footer {
  padding: 35px 0;
  /* background-color: #fafafa; */
}

.footer-logo img {
  width: 200px;
  max-width: 100%;
}

.footer-heading {
  font-weight: bold;
  margin-top: 0;
  font-size: 18px;
  margin-bottom: 30px;
}

.footer p {
  color: #222222;
}

.footer a {
  display: inline-block;
  text-decoration: none;
  color: #222222;
  font-family: 'Bring Font', sans-serif;
  transition: all .3s ease;
}

.footer a:hover {
  color: #f16b2f;
}

.footer ul {
  padding: 0;
  margin: 0;
}

.footer ul li {
  list-style-type: none;
  margin-bottom: 20px;
}

.footer-social-links li {
  display: inline-block;
}

.footer-social-links li a {
  display: inline-block;
  margin-right: 15px;
  height: 30px;
  width: 30px;
  line-height: 36px;
  text-align: center;
  border-radius: 50%;
  transition: all .3s ease;
  border: 1px solid #cdcdcd;
}

.footer-social-links li a:hover {
  border-color: #f16b2f;
}

.footer-email {
  line-height: 1.5em;
}


.job-section {
  padding: 100px 0;
}

.table-locantion {
  display: flex;
  align-items: center;
}

.job-description {
  margin: 0;
  text-align: left;
}

.job-description p {
  margin: 0;
}

.job-description a {
  color: #a633db;
  text-decoration: none;
  transition: all .3s ease;
}

.job-description a:hover {
  color: #f16b2f;
}

.job-description ul, .job-description ol {
  margin: 0;
}

.table-locantion p {
  color: #f16b2f;
  margin: 0;
}

.table-locantion p:last-child {
  margin-bottom: 4px;
  margin-left: 6px;
  color: #222222;
}


/*************************** Media Queries **************************/

@media (max-width: 850px) {
  .banner-heading {
    font-size: 3rem;
  }
}

@media (max-width: 1135px) {
  .career-form-banner {
    margin-top: 0;
  }
}

/*********************** Keyframes Animations ***********************/

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}