.header {
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    padding: 10px;
    position: fixed;
    background-color: #ffffff;
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Bring Font', sans-serif;
}

.navbar-brand {
    max-width: 200px;
    display: flex;
    align-items: center;
}

.navbar-brand img {
    width: 100%;
}

.navbar .nav-item {
    display: inline-block;
    list-style-type: none;
}

.nav-list {
    margin: 0;
}

.header-btn a {
    display: block;
    padding: 10px 25px !important;
    color: #ff6c2f !important;
    background-color: #fbd6c4;
    border-radius: 25px;
    margin-left: 15px;
}

.header-btn a:hover {
    color: #ffffff !important;
    background-color: #ff6c2f;
}

.nav-list .nav-item a {
    display: block;
    padding: 15px;
    color: #a633db;
    text-decoration: none;
    transition: all .3s ease;
}

.nav-list .nav-item a:hover {
    color: #f16b2f;
}

.nav-list .nav-item a.active {
    color: #f16b2f;
}


.mobile-menu {
    display: none;
}

@media (max-width: 850px) {
    .mobile-menu {
        display: block;
    }

    .menu-icon {
        width: 40px;
        height: 40px;
    }

    .nav-list {
        display: flex;
        width: 100%;
        height: 240px;
        position: absolute;
        top: 65px;
        left: 0;
        right: 0;
        display: none;
        transition: all 0.3s ease;
        flex-direction: column;
        list-style-type: none;
        grid-gap: 0px;
    }

    .nav-list .nav-item {
        display: block;
    }

    .nav-list.responsive {
        background: #fafafa;
        display: block;
        transition: all 0.5s ease;
        z-index: 1;
        align-content: center;
        padding-left: 0px;
    }

    .header-btn a {
        margin-left: 0;
    }
}